import {createNewAssessment} from '../actionCreators/assessmentActionCreators';
import {
	ASSESSMENT_ADD_THREAT,
	ASSESSMENT_LOAD,
	ASSESSMENT_REMOVE_THREAT,
	ASSESSMENT_REPLACE_THREAT,
	ASSESSMENT_RESET,
	ASSESSMENT_SET_COASSESSORS,
	ASSESSMENT_THREAT_ADD_TASK,
	ASSESSMENT_THREAT_REMOVE_TASK,
	ASSESSMENT_THREAT_UPDATE_TASK,
	ASSESSMENT_UPDATE,
} from '../actionTypes';

const initialState = createNewAssessment();

const assessmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case ASSESSMENT_UPDATE:
			return {
				...state,
				[action.payload.field]: {
					...state[action.payload.field],
					[action.payload.subField]: action.payload.value,
				},
			};
		case ASSESSMENT_ADD_THREAT:
			return {
				...state,
				threats: [action.payload, ...state.threats],
			};
		case ASSESSMENT_REMOVE_THREAT:
			return {
				...state,
				threats: state.threats.filter(t => t.key !== action.payload.key),
			};
		case ASSESSMENT_REPLACE_THREAT:
			return {
				...state,
				threats: state.threats.map(t =>
					t.key === action.payload.key ? action.payload.newThreat : t,
				),
			};
		case ASSESSMENT_THREAT_ADD_TASK:
			return {
				...state,
				threats: state.threats.map(threat =>
					threat.key !== action.payload.threatKey
						? threat
						: {
								...threat,
								tasks: [action.payload.newTask, ...threat.tasks],
						  },
				),
			};
		case ASSESSMENT_THREAT_UPDATE_TASK:
			return {
				...state,
				threats: state.threats.map(threat =>
					threat.key !== action.payload.threatKey
						? threat
						: {
								...threat,
								tasks: threat.tasks.map(task =>
									task.key !== action.payload.taskKey
										? task
										: {
												...task,
												[action.payload.field]: action.payload.value,
										  },
								),
						  },
				),
			};
		case ASSESSMENT_THREAT_REMOVE_TASK:
			return {
				...state,
				threats: state.threats.map(threat =>
					threat.key !== action.payload.threatKey
						? threat
						: {
								...threat,
								tasks: threat.tasks.filter(
									t => t.key !== action.payload.taskKey,
								),
						  },
				),
			};

		case ASSESSMENT_SET_COASSESSORS:
			return {
				...state,
				coassessors: action.payload,
			};
		case ASSESSMENT_LOAD:
			return {
				...action.payload,
				notes: action.payload.notes ? action.payload.notes : {},
			};
		case ASSESSMENT_RESET:
			return createNewAssessment();
		default:
			return state;
	}
};

export default assessmentReducer;
