import React from 'react';
import keycloak from './keycloak';
import {isUserAdmin} from './utils/accessControl';

const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));

const Results = React.lazy(() => import('./views/Results/Results'));
const DeletedResults = React.lazy(() =>
	import('./views/Results/DeletedResults'),
);
const Result = React.lazy(() => import('./views/Results/Result'));
const Help = React.lazy(() => import('./views/Help/Help'));

const getRoutes = auth => {
	if (!auth) return [];

	const routes = [
		{path: '/', exact: true, name: 'Home'},
		{
			path: '/dashboard/:assesmentId',
			name: 'Dashboard',
			component: Dashboard,
		},
		{path: '/dashboard', name: 'Dashboard', component: Dashboard},
		{
			path: '/results',
			exact: true,
			name: 'Results',
			component: Results,
		},
		{
			path: '/deleted-results',
			exact: true,
			name: 'Deleted Results',
			component: DeletedResults,
		},
		{
			path: '/results/:id',
			exact: true,
			name: 'View Results',
			component: Result,
		},
	];

	try {
		if (isUserAdmin(auth)) {
			// Admin User paths
			routes.push({
				path: '/users',
				exact: true,
				name: 'Users',
				component: Users,
			});
			routes.push({
				path: '/users/:id',
				exact: true,
				name: 'User Details',
				component: User,
			});
			routes.push({
				path: '/new/user',
				exact: true,
				name: 'New User',
				component: User,
			});
		}
	} catch (e) {
		keycloak.logout();
	}

	routes.push({
		path: '/help',
		exact: true,
		name: 'Help & Feedback',
		component: Help,
	});

	return routes;
};

export default getRoutes;
