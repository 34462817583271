import {
	AppBreadcrumb2 as AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
import React, {Suspense, useState} from 'react';
import {useSelector} from 'react-redux';
import * as router from 'react-router-dom';
import {Redirect, Route, Switch} from 'react-router-dom';
import {
	Button,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import getNavigationConfig from '../../_nav';
import Loading from '../../components/Loading';
import getRoutes from '../../routes';
import {userCanAccessApp} from '../../utils/accessControl';

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

const LoadingComponent = <Loading>Loading...</Loading>;

const DefaultLayout = props => {
	const auth = useSelector(state => state.auth);
	const [isOpen, setIsOpen] = useState(false);

	if (!userCanAccessApp(auth)) return <Redirect to="/no-auth" />;

	const routes = getRoutes(auth);

	return (
		<div className="app">
			<AppHeader fixed>
				<Suspense fallback={null}>
					<DefaultHeader />
				</Suspense>
			</AppHeader>
			<div className="app-body">
				<AppSidebar fixed display="lg">
					<AppSidebarHeader />
					<AppSidebarForm />
					<Suspense fallback={null}>
						<AppSidebarNav
							navConfig={getNavigationConfig(auth)}
							{...props}
							router={router}
						/>
					</Suspense>
					<AppSidebarFooter>
						<div onClick={() => setIsOpen(!isOpen)}>
							<i className="nav-icon icon-control-play" />
							&nbsp; &nbsp;Application Tutorial
						</div>
						<Modal
							isOpen={isOpen}
							toggle={() => setIsOpen(!isOpen)}
							size={'xl'}
							className={'modal-info'}>
							<ModalBody>
								<h1>Application Tutorial</h1>
								<video
									controls
									src="https://rest.site4security.com/api/courses/webinars/SRA_Online_Application_Tutorial.mp4"
									className="tutorial-video"
								/>
							</ModalBody>
						</Modal>
					</AppSidebarFooter>
				</AppSidebar>
				<main className="main">
					<AppBreadcrumb appRoutes={routes} router={router} />
					<Container fluid>
						<Suspense fallback={LoadingComponent}>
							<Switch>
								{routes.map((route, idx) => {
									return route.component ? (
										<Route
											key={idx}
											path={route.path}
											exact={route.exact}
											name={route.name}
											render={props => <route.component {...props} />}
										/>
									) : null;
								})}
								<Redirect from="/" to="/dashboard" />
							</Switch>
						</Suspense>
					</Container>
				</main>
			</div>
			<AppFooter>
				<Suspense fallback={null}>
					<DefaultFooter />
				</Suspense>
			</AppFooter>
		</div>
	);
};

export default DefaultLayout;
