// Auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_FETCH_USER = 'AUTH_FETCH_USER';

// Assessment
export const ASSESSMENT_UPDATE = 'ASSESSMENT_UPDATE';
export const ASSESSMENT_RESET = 'ASSESSMENT_RESET';
export const ASSESSMENT_LOAD = 'ASSESSMENT_LOAD';

export const ASSESSMENT_ADD_THREAT = 'ASSESSMENT_ADD_THREAT';
export const ASSESSMENT_REMOVE_THREAT = 'ASSESSMENT_REMOVE_THREAT';
export const ASSESSMENT_UPDATE_THREAT = 'ASSESSMENT_UPDATE_THREAT';
export const ASSESSMENT_REPLACE_THREAT = 'ASSESSMENT_REPLACE_THREAT';

export const ASSESSMENT_THREAT_ADD_TASK = 'ASSESSMENT_THREAT_ADD_TASK';
export const ASSESSMENT_THREAT_REMOVE_TASK = 'ASSESSMENT_THREAT_REMOVE_TASK';
export const ASSESSMENT_THREAT_UPDATE_TASK = 'ASSESSMENT_THREAT_UPDATE_TASK';

export const ASSESSMENT_SET_COASSESSORS = 'ASSESSMENT_SET_COASSESSORS';
