export const userCanAccessApp = auth => {
	try {
		const {
			resourceAccess: {
				'security-risk-app': {roles},
			},
		} = auth;

		return roles.includes('admin') || roles.includes('user');
	} catch {
		return false;
	}
};

export const isUserAdmin = auth => {
	try {
		const {
			resourceAccess: {
				'security-risk-app': {roles},
			},
		} = auth;

		return roles.includes('admin');
	} catch {
		return false;
	}
};
