// import {reducer as toastr} from 'react-redux-toastr';
import {combineReducers} from 'redux';

import auth from './authReducer';
import assessment from './assessmentReducer';
import app from './appReducer';

const rootReducer = combineReducers({
	// toastr,
	auth,
	assessment,
	app,
});

export default rootReducer;
