import {Popconfirm} from 'antd';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ParallaxProvider} from 'react-scroll-parallax';
import {Col, Container, Row, Button, ButtonGroup} from 'reactstrap';
import {resetAssessment} from '../../redux/actionCreators/assessmentActionCreators';
import Header from './Header';
import Slider from './Slider';

const HomeLayout = ({history}) => {
	const dispatch = useDispatch();
	const assessmentInProgress = useSelector(
		state => state.app.assessmentInProgress,
	);

	const title1 = 'Welcome to the AKDN Department of International Security';
	const title2 = 'RISK ASSESSMENT TOOL';

	return (
		<main className="home-page">
			<Container fluid className="px-0">
				<Row noGutters className="justify-content-center">
					<Col>
						<Header />
					</Col>
				</Row>

				<ParallaxProvider>
					<Slider id={'hero'} className={'vh-100'}>
						<div className={'hero-content'}>
							<Container>
								<Row noGutters>
									<Col className={'slogan'}>
										<div className="heading">
											<img
												className="logo"
												src={'/assets/img/akdn_logo_text.png'}
												alt={'logo'}
											/>
										</div>
										<Row className="pb-xl-1 title-container">
											<span>{title1}</span>
											<span>{title2}</span>
										</Row>
										<Row className="mt-4">
											{assessmentInProgress && (
												<Col>
													<Popconfirm
														title="If you wish to save the unfinished assessment, then click the button and then click ‘Save Assessment’ on the Risk Register page after you finish your assessment."
														onConfirm={() => {
															history.push('/dashboard');
														}}>
														<Button block color="akdn-warning" size="lg">
															Continue Unfinished Assessment
														</Button>
													</Popconfirm>
												</Col>
											)}
										</Row>
										<Row className="mt-4">
											<Col>
												{assessmentInProgress ? (
													<Popconfirm
														title="You have an unfinished assessment, if you proceed you will lose unsaved assessment!"
														onConfirm={() => {
															dispatch(resetAssessment());
															history.push('/dashboard');
														}}>
														<Button block color="akdn-primary" size="lg">
															Begin New Assessment
														</Button>
													</Popconfirm>
												) : (
													<Button
														block
														color="akdn-primary"
														size="lg"
														onClick={() => {
															dispatch(resetAssessment());
															history.push('/dashboard');
														}}>
														Begin New Assessment
													</Button>
												)}
											</Col>
											<Col>
												<Button
													tag={Link}
													to={'/results'}
													block
													color="akdn-secondary"
													size="lg">
													View / Edit Completed Assessments
												</Button>
											</Col>
										</Row>
									</Col>
									<Col />
								</Row>
							</Container>
						</div>
					</Slider>
				</ParallaxProvider>
			</Container>
		</main>
	);
};

export default HomeLayout;
