// General
import React from 'react';

export const countries = [
	'Afghanistan',
	'Albania',
	'Algeria',
	'Angola',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bhutan',
	'Bolivia',
	'Bosnia and Herzegovina',
	'Botswana',
	'Brazil',
	'Brunei Darussalam',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Cape Verde',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Comoros',
	'ROC',
	'DRC',
	'Costa Rica',
	"Côte d'Ivoire",
	'Croatia',
	'Cuba',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Ethiopia',
	'Fiji',
	'Finland',
	'France',
	'French Guiana',
	'French Polynesia',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Greece',
	'Greenland',
	'Grenada',
	'Guatemala',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Holy See',
	'Honduras',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran, Islamic Republic of',
	'Iraq',
	'Ireland',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'North Korea',
	'South Korea',
	'Kuwait',
	'Kyrgyzstan',
	'Lao PDR',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'North Macedonia, Republic of',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Mauritania',
	'Mauritius',
	'Mexico',
	'Micronesia, Federated States of',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Northern Mariana Islands',
	'Norway',
	'Oman',
	'Pakistan',
	'Palau',
	'Palestinian Territory',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Poland',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Réunion',
	'Romania',
	'Russian Federation',
	'Rwanda',
	'Samoa',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Swaziland',
	'Sweden',
	'Switzerland',
	'Syrian Arab Republic',
	'Taiwan, Republic of',
	'Tajikistan',
	'Tanzania, United Republic of',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'United States of America',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Venezuela',
	'Viet Nam',
	'Western Sahara',
	'Yemen',
	'Zambia',
	'Zimbabwe',
];

export const backgroundColors = [
	'#FFFFFF',
	'#4dbd74',
	'#20a8d8',
	'#ffc107',
	'#f88f2a',
	'#f63c3a',
];

export const fontColors = [
	'#000000',
	'#000000',
	'#000000',
	'#000000',
	'#000000',
	'#FFFFFF',
];

// Scope
export const scopeTypeOptions = [
	{
		id: 'event',
		title: 'Event',
	},
	{
		id: 'project',
		title: 'Project',
	},
	{
		id: 'building',
		title: 'Building',
	},
	{
		id: 'premises',
		title: 'Premises',
	},
	{
		id: 'area',
		title: 'Area',
	},
	{
		id: 'city',
		title: 'City',
	},
	{
		id: 'country',
		title: 'Country',
	},
];

// Threat
export const threatTypes = [
	'Abduction/hostage taking',
	'Armed incident – AKDN / Jamat targeted',
	'Armed incident – incidental',
	'Arrest/detention',
	'Arson',
	'Assault',
	'Burglary',
	'Facility intrusion',
	'Harassment/intimidation/threat of violence',
	'Hostile surveillance or reconnaissance',
	'Missing person',
	'Movement restriction',
	'Public gathering – non-violent',
	'Public gathering – violent',
	'Robbery',
	'Seizure',
	'Sexual assault',
	'Theft',
	'Vandalism',
	'Accident – aircraft',
	'Accident – fire',
	'Accident – other transportation',
	'Accident – vehicular',
	'Accidents – other',
	'Earthquake',
	'Flood',
	'Landslide/mudslide/avalanche',
	'Destructive weather system',
	'Tsunami',
	'Volcanic eruption',
	'Industrial disaster',
	'Explosive remnant of war',
	'Medical Epidemic',
	'Medical Emergency',
	'Other',
];

export const threatCategories = [
	'Armed conflict',
	'Terrorism',
	'Crime',
	'Civil unrest',
	'Hazard/Natural Disaster',
	'Other',
];

export const threatInfoData = [
	{
		key: 1,
		title: 'categories',
		descriptions: [
			'a. Armed conflict',
			'b.	Terrorism',
			'c.	Crime',
			'd.	Civil unrest',
			'e.	Hazards',
		],
	},
	{
		key: 2,
		title: 'existence',
		descriptions: [
			'1.	No indication that this threat is currently a factor or could be a factor in the area of operation',
			'2.	No indication that this threat is currently a factor, but it could be a factor in the area of operation',
			'3.	This threat may currently be a factor within the wider context',
			'4.	Several indications suggest that this threat is currently a factor in the area of operation',
			'5.	This threat is currently a factor in the area of operation',
		],
	},
	{
		key: 3,
		title: 'capability',
		descriptions: [
			'1.	Evidence that there is no capability to execute',
			'2.	Minor/limited capability to execute',
			'3.	Moderate capability to execute',
			'4.	Substantial capability to execute',
			'5.	Full demonstrated capability to execute',
		],
	},
	{
		key: 4,
		title: 'history',
		descriptions: [
			'1. There is no data on actions against AKDN/Jamat or similar organizations',
			'2.	Actions have occurred against similar organisations, but not AKDN/Jamat',
			'3.	There is a history of unsuccessful actions against AKDN/Jamat or similar organizations',
			'4.	Actions have occurred several times against AKDN/Jamat',
			'5.	Actions have occurred several times against AKDN/Jamat and similar organisations',
		],
	},
	{
		key: 5,
		title: 'intention',
		descriptions: [
			'1. No intent to execute actions against AKDN/Jamat',
			'2.	Only expressed intent or evidence that actions are seen as an option',
			'3.	Fully demonstrated intent to execute actions against AKDN/Jamat but with only preliminary planning',
			'4.	Actions have already been executed (not against AKDN/Jamat) or evidence of advanced planning and preparation against AKDN/Jamat',
			'5. Fully demonstrated intent to execute actions against AKDN/Jamat (have already executed actions against AKDN/Jamat)',
		],
	},
	{
		key: 6,
		title: 'targeting',
		descriptions: [
			'1.	There is no information that preparations are in place for any actions',
			'2.	General information received on possible actions in area of operation',
			'3.	Security threat information from credible sources indicate upcoming actions against AKDN/Jamat',
			'4.	Hostile surveillance has been detected against AKDN/Jamat',
			'5.	There is evidence that actions against AKDN is in final stages of planning',
		],
	},
	{
		key: 7,
		title: 'hazardHistory',
		descriptions: [
			'Not prone to hazard events',
			'Hazard events occur occasionally',
			'Hazard events occur frequently',
			'Prone to predictable hazard events and/or hazard event imminent',
			'Prone to sudden onset of hazard events',
		],
	},
	{
		key: 8,
		title: 'hazardIntensifySeverity1',
		descriptions: [
			'Limited',
			'Moderate',
			'Severe',
			'Devastating',
			'Multiple and devastating',
		],
	},
	{
		key: 9,
		title: 'hazardIntensifySeverity2',
		descriptions: [
			'Effective warning and preparedness systems in place',
			'Partial/limited warning and/or preparedness systems in place',
			'Warning and/or preparedness systems in place not fully effective',
			'Warning and/or preparedness systems are untested or unknown',
			'No warning and/or preparedness systems in place',
		],
	},
];
export const threatPointTypeTitles = {
	categories: 'categories',
	existence: 'existence',
	capability: 'capability',
	history: 'history',
	intention: 'intention',
	targeting: 'targeting',
	hazardHistory: 'History',
	hazardIntensifySeverity1: 'Intensify/Severity',
	hazardIntensifySeverity2: 'Intensify/Severity',
};

export const threatTableData = [
	{
		key: '1',
		level: ' 5 - Severe (Red)',
		capability: <div className="threat-bulletClass">&#x2022;</div>,
		history: <div className="threat-bulletClass">&#x2022;</div>,
		intentions: <div className="threat-bulletClass">&#x2022;</div>,
	},
	{
		key: '2',
		level: ' 4 - High (Orange)',
		capability: <div className="threat-bulletClass">&#x2022;</div>,
		history: <div className="threat-bulletClass">&#x2022;</div>,
		intentions: <div className="threat-bulletClass">&#x2022;</div>,
	},
	{
		key: '3',
		level: '3 - Elevated (Yellow)',
		capability: <div className="threat-bulletClass">&#x2022;</div>,
		history: <div className="threat-bulletClass">&#x2022;</div>,
		intentions: <div className=".threat-cubeClass">&#10066;</div>,
	},
	{
		key: '4',
		level: '2 - Guarded (Blue)',
		capability: <div className="threat-bulletClass">&#x2022;</div>,
		history: <div className=".threat-cubeClass">&#10066;</div>,
		intentions: '',
	},
	{
		key: '5',
		level: '1 - Low (Green)',
		capability: <div className=".threat-cubeClass">&#10066;</div>,
		history: '',
		intentions: '',
	},
];
// Vulnerability

export const vulnerabilityInfoData = [
	{
		title: 'experience',
		titleText: 'experience',
		descriptionText:
			'The overall level of experience and knowledge of all staff/volunteers with regard to security.' +
			' Note that the rating for this component depends on the current composition of the team and will' +
			' change as team members join or leave.',
		options: [
			'Very high level of experience throughout.',
			'Senior staff and senior volunteers very experienced.',
			'Security team somewhat experienced.',
			'Few senior staff and senior volunteers with experience.',
			'Very low level of experience throughout.',
		],
	},
	{
		title: 'training',
		titleText: 'training',
		descriptionText:
			'This component describes the level of security training in which staff/volunteers have participated. ' +
			'Consider all the different security trainings that may have been delivered; have all staff/volunteers been trained ' +
			'or only a very small number?',
		options: [
			'Most staff/volunteers have core security training.',
			'Staff/volunteers are trained in general.',
			'Staff/volunteers somewhat trained.',
			'Key staff/volunteers are lacking security training.',
			'Security training severely lacking throughout.',
		],
	},
	{
		title: 'awareness',
		titleText: 'Awareness/Compliance',
		descriptionText:
			'What is the level of security awareness among staff/volunteers? This component looks only at how aware ' +
			'staff/volunteers are of security issues and procedures. This component does not look at how well they comply ' +
			'with security rules.',
		options: [
			'Very good overall security awareness and compliance.',
			'Good overall security awareness and compliance.',
			'Reasonable overall security awareness and compliance.',
			'Poor overall security awareness and compliance.',
			'Very poor overall security awareness and compliance.',
		],
	},
	{
		title: 'planning',
		titleText: 'planning',
		descriptionText:
			'What’s the rating in terms of security planning? Is there a security plan? Separate security contingency ' +
			'plans for events such as fires or MEDEVAC? For critical incidents?',
		options: [
			'Comprehensive plans are in place.',
			'Most plans are in place, but not rehearsed.',
			'Plans are somewhat in place and rehearsed.',
			'Plans are weak and rarely rehearsed.',
			'Plans are generally lacking.',
		],
	},
	{
		title: 'assignment of responsibilities',
		titleText: 'Security culture',
		descriptionText:
			'Do all staff/volunteers understand their security responsibilities? Does everyone take responsibility ' +
			'for his/her own security? How would you rate the overall culture of security? Does everyone know what to ' +
			'do in a crisis? Do people respect security rules and procedures?',
		options: [
			'Very good overall security culture.',
			'Good overall security culture.',
			'Reasonable overall security culture.',
			'Poor overall security culture.',
			'Very poor overall security culture.',
		],
	},
	{
		title: 'reactions to incidents',
		titleText: 'Incident management',
		descriptionText:
			'Is there a system in place for reporting security incidents? Who analyses the incidents? Is the analysis ' +
			'fed into updates of the security risk assessment? Are trainings or briefings initiated after incidents?',
		options: [
			'Very good incident management system in place.',
			'Good incident management system in place.',
			'Reasonable incident management system in place.',
			'Poor incident management system in place.',
			'No incident management system in place.',
		],
	},
	{
		title: 'acceptance',
		titleText: 'acceptance',
		descriptionText:
			'Is there uniformly acceptance to AKDN/Jamat presence in the broader area? Is acceptance continuously ' +
			'developed? Do we have acceptance for all the areas we travel through?',
		options: [
			'AKDN/Jamat has acceptance in the broader region.',
			'AKDN/Jamat has acceptance locally.',
			'Acceptance is sporadic.',
			'Very little acceptance.',
			'Very little acceptance and unpopular activities.',
		],
	},
	{
		title: 'physical protection',
		titleText: 'physical protection',
		descriptionText:
			'Is the physical security component commensurate with the threat?',
		options: ['Yes', 'Mostly', 'Somewhat', 'Weak', 'No'],
	},
];
export const vulnerabilityColumns = [
	{
		title: '',
		dataIndex: 'description',
	},
	{
		title: (
			<div
				className="scoreStyle"
				style={{
					backgroundColor: backgroundColors[1],
				}}>
				1
			</div>
		),
		dataIndex: 'option1',
	},
	{
		title: (
			<div
				className="scoreStyle"
				style={{
					backgroundColor: backgroundColors[2],
				}}>
				2
			</div>
		),
		dataIndex: 'option2',
	},
	{
		title: (
			<div
				className="scoreStyle"
				style={{
					backgroundColor: backgroundColors[3],
				}}>
				3
			</div>
		),
		dataIndex: 'option3',
	},
	{
		title: (
			<div
				className="scoreStyle"
				style={{
					backgroundColor: backgroundColors[4],
				}}>
				4
			</div>
		),
		dataIndex: 'option4',
	},
	{
		title: (
			<div
				className="scoreStyle"
				style={{
					backgroundColor: backgroundColors[5],
				}}>
				5
			</div>
		),
		dataIndex: 'option5',
	},
	{
		title: 'Score',
		dataIndex: 'score',
		render: text => {
			return (
				<div
					className="scoreStyle"
					style={{
						backgroundColor: backgroundColors[text],
					}}>
					{text}
				</div>
			);
		},
	},
];

//Likelihood
export const cardData = [
	{
		title: 'About Threat Scores',
		description: 'Threat Scores span between 1 and 25.',
	},
	{
		title: 'About Vulnerability Scores',
		description:
			'Vulnerability Scores range between 8 (minimum) and 40 (maximum). The minimum translates ' +
			'the impossibility to be perfectly immune of all types of threats.',
	},
	{
		title: 'Likelihood evaluation',
		description:
			'Likelihood of the threat becoming an incident is evaluated by ' +
			"multiplying the threat and vulnerability scores. By doing so, the likelihood will have values between 8 and 1'000.",
	},
];

// Impact
export const impactInfoData = [
	{
		title: 'people',
		titleText: 'people',
		options: [
			'No injury or injury requiring first aid treatment.',
			'Injury requiring treatment by medical practitioner.',
			'Injury requiring hospitalisation.',
			'Single death and / or multiple injuries requiring hospitalisation.',
			'Multiple deaths.',
		],
	},
	{
		title: 'information',
		titleText: 'information',
		options: [
			'No information compromised or compromise of information available in the public domain.',
			'Some compromise of information sensitive to internal interests.',
			'Compromise of information sensitive to operations.',
			"Compromise of information sensitive to the organisation's interests.",
			'Compromise of information with significant ongoing impact.',
		],
	},
	{
		title: 'property',
		titleText: 'property (Asset)',
		options: [
			'No damage or minor damage or vandalism to asset.',
			'Damage or loss of 5% of total asset.',
			'Damage or loss of 5-20% of total asset.',
			'Damage or loss of 20-50% of total asset.',
			'Destruction or complete loss of >50% of assets.',
		],
	},
	{
		title: 'economic',
		titleText: 'economic',
		options: [
			'<2% of budget.',
			'2-5% of budget.',
			'5-10% of budget.',
			'10-30% of budget.',
			'>30% of budget.',
		],
	},
	{
		title: 'reputation',
		titleText: 'reputation',
		options: [
			'No attention; quickly forgotten; freedom to operate unimpacted.',
			'Short term local attention - some impact on local level activities.',
			'Persistent national attention; longer term impact on activities.',
			'Persistent and intense national attention and media scrutiny - long term impact on activities.',
			'International attention; sustained adverse media scrutiny - significant impact on organisations activities.',
		],
	},
	{
		title: 'capability',
		titleText: 'capability',
		options: [
			'No impact; minimal impact on non-core operations; can be dealt with within normal operations.',
			'Some impact on organisational capability in terms of delays / systems quality; can be dealt with at an operations level.',
			'Impact on the organisation resulting in reduced performance such that targets are not met; organisation is not threatened.',
			'Breakdown of key activities leading to a reduction in performance - e.g. service delays, legal breaches, economic loss, dissatisfaction.',
			'Protracted availability of critical skills / people; critical failure(s) preventing activities from being performed; organisation or project is threatened.',
		],
	},
];
export const impactColumns = [
	{
		title: '',
		dataIndex: 'description',
	},
	{
		title: (
			<>
				<p>1</p>
				<p>Negligible</p>
			</>
		),
		dataIndex: 'option1',
	},
	{
		title: (
			<>
				<p>2</p>
				<p>Minor</p>
			</>
		),
		dataIndex: 'option2',
	},
	{
		title: (
			<>
				<p>3</p>
				<p>Moderate</p>
			</>
		),
		dataIndex: 'option3',
	},
	{
		title: (
			<>
				<p>4</p>
				<p>Severe</p>
			</>
		),
		dataIndex: 'option4',
	},
	{
		title: (
			<>
				<p>5</p>
				<p>Critical</p>
			</>
		),
		dataIndex: 'option5',
	},
	{
		title: <p>Score</p>,
		dataIndex: 'score',
		render: text => {
			return (
				<div
					className="scoreStyle"
					style={{
						backgroundColor: backgroundColors[text],
					}}>
					{text}
				</div>
			);
		},
	},
];

// Risk
export const riskValues = [
	[1, 1, 1, 2, 2], // 1
	[1, 2, 2, 3, 3], // 2
	[1, 2, 3, 4, 4], // 3
	[2, 3, 4, 4, 5], // 4
	[2, 3, 4, 5, 5], // 5
];

export const riskLegendData = [
	{
		title: 'Very Low Risk',
		color: '#4dbd74',
	},
	{
		title: 'Low Risk',
		color: '#20a8d8',
	},
	{
		title: 'Medium Risk',
		color: '#ffc107',
	},
	{
		title: 'High Risk',
		color: '#f88f2a',
	},
	{
		title: 'Very High Risk',
		color: '#f63c3a',
	},
];

// Texts
export const vulnerabilityTexts = {
	1: 'Very Low Vulnerability',
	2: 'Low Vulnerability',
	3: 'Medium Vulnerability',
	4: 'High Vulnerability',
	5: 'Very High Vulnerability',
};

export const likelihoodTexts = {
	1: 'Very Unlikely',
	2: 'Unlikely',
	3: 'Possible',
	4: 'Likely',
	5: 'Very Likely',
};

export const impactTexts = {
	1: 'Negligible',
	2: 'Minor',
	3: 'Moderate',
	4: 'Severe',
	5: 'Critical',
};

export const riskTexts = {
	1: 'Very Low',
	2: 'Low',
	3: 'Medium',
	4: 'High',
	5: 'Very High',
};

// RiskManagement
export const impactedEntities = [
	'(P) People',
	'(R) Reputation',
	'(E) Economic',
	'(C) Capability',
	'(I) Information',
	'(P) Property',
];

export const riskRegisterOptions = {
	'Physical Security': {
		'Perimeters and Barriers': [
			'Perimeter Fencing',
			'Perimeter Wall',
			'Electric fencing',
			'Barbed Wire/Razor Wire/Concertina Wire',
			'Fence Cap (Anti-Climb)',
			'Landscaping/Remove Obstructions',
			'Footpath Clearance',
			'Natural Barriers (Hills/Rivers etc)',
			'Separate Entrances for Staff/Visitors',
			'Pedestrian Drop-Off Points',
			'Stand Off Distance to Buildings',
			'Establish Offsite Parking Area',
			'Parking Gates/Control',
			'Vehicle Barriers/Bollards',
			'Speed Bumps',
			'Blast Barriers',
			'Other',
		],
		Lighting: [
			'Perimeters Lighting',
			'External Building Lighting',
			'Internal Lighting',
			'Parking Area Lighting',
			'Foot Path Lighting',
			'Emergency Lighting',
			'Portable Lighting',
			'Flood Lighting',
			'Motion Sensitive Lighting',
			'Access Point Lighting',
			'Other',
		],
		'Access Control': [
			'Maglocks',
			'RFID Readers',
			'Biometric Readers',
			'Password Locks (PIN)',
			'Elevator Controls',
			'Lockdown Systems',
			'Vehicle Tags',
			'K-9 (Dogs)',
			'Mail and Delivery Screening Area',
			'Personal Search Area',
			'Door Reinforcement',
			'Turnstiles/Revolving Doors',
			'Window Bars/Grills/Mesh',
			'Security Shutters',
			'Fire Doors',
			'Inward Opening Doors',
			'Outward Opening Doors',
			'Other',
		],
		'Locks and Latches': [
			'Key Management System',
			'Central Locking System',
			'Proximity Locks',
			'Other',
		],
		'Electronic Surveillance': [
			'CCTV (External/Internal)',
			'Wireless Cameras (web based)',
			'Infrared Cameras',
			'Video Analytics',
			'CCTV Monitoring',
			'Server Installation/Upgrades',
			'Other',
		],
		'Facility  Alarm Systems': [
			'Motion Detection',
			'Vibration Detectors',
			'Capacitance Detectors',
			'Buried Cable Detector',
			'Microwave Detector',
			'Infrared detector',
			'Heat Sensor',
			'Silent Alarm System',
			'Audible Alarm Systems',
			'Panic Buttons/Alarms',
			'Other',
		],
		'Indicative Signage': [
			'Notices and Warnings',
			'Directional Signage',
			'Instructional Signage',
			'Identification Signage',
			'Fire Safety Signage',
			'Evacuation Signage',
			'Collection Point Signage',
			'Other',
		],
		'Safes, Vaults and Saferooms': [
			'Cash Safe',
			'Document Safe',
			'Saferoom',
			'Saferoom Equipment',
			'Saferoom Toilet/Waste Collection',
			'Other',
		],
		'Structural Design/Improvements': [
			'Roof protection',
			'Steel Frames',
			'Reinforced External Walls and Framing',
			'Internal Partition Reinforcement',
			'Compartmentalization',
			'Retrofitting',
			'Window Reinforcements',
			'Floor Reinforcement',
			'Anti-Slip Flooring and Staircases',
			'Handrails',
			'Guard Booths/Towers',
			'Other',
		],
		'Seismic Safety': [
			'Seismic Vulnerability Assessment',
			'Anchored Wall Hangings',
			'Anchored Furniture/Equipment',
			'Use of Light Ceiling Materials',
			'Reinforced Walls/Columns',
			'Reinforced Door Frames',
			'Base Isolation and Dampers',
			'Cross Bracing',
			'Install Shear Anchors',
			'Install Tension Ties',
			'Other',
		],
		'Blast protection': [
			'Blast Wall',
			'Hesco Barriers',
			'Natural Barriers',
			'Reinforced Barriers',
			'Reinforced Glass',
			'Shatter Resistant Glass',
			'Blast Film',
			'Bomb Shelters',
			'Other',
		],
		'Facility Support Services/Areas': [
			'Loading Dock Controls',
			'Waste Disposal Controls',
			'HVAC Intake Controls',
			'Underground Area Controls',
			'Water Supply/Tank Controls',
			'Generator (Emergency Power)',
			'Other',
		],
	},
	Equipment: {
		Communications: [
			'Mobile Phones and Apps',
			'HF/VHF Radios',
			'Satellite Phones',
			'Hand Held Radios',
			'People Alert Devices',
			'Announcers/Bullhorns/PA Systems',
			'Visual Alert Lighting/Alarms',
			'Direct Phone Lines',
			'Other',
		],
		'Emergency Response': [
			'Digging Tools (Shovel, Axes, Pickaxes)',
			'Search Lights',
			'Ropes',
			'Ladders',
			'Earthquake Rescue tools (basic)',
			'Earthquake Rescue Tools (Hydraulic)',
			'Handicap Rescue Stair Chair',
			'Emergency Storage Container Refrigerated)',
			'Emergency Equipment Storage Container',
			'Other',
		],
		Facilities: [
			'Anti-slip floor Coatings and Tape',
			'Safe HAZMAT Storage Areas',
			'Decontamination Kits',
			'Other',
		],
		'Fire safety': [
			'Fire Extinguishers (A, B,C)',
			'Fire Extinguishers (D, K)',
			'Fire Extinguishers (Specialised)',
			'Sprinklers (Water, CO2)',
			'Fire Blankets',
			'Smoke Detectors',
			'Gas Detectors',
			'Heat Detectors',
			'Flame Detectors',
			'Manually Activated Alarms',
			'Manual Alarms (Bells, Whistles, Gongs)',
			'Fire Alarm Panel',
			'Sand Buckets',
			'Other',
		],
		'Medical Response': [
			'Facility First Aid Kits (Basic)',
			'Facility Trauma Kits (Advanced)',
			'Vehicle First Aid Kits (Basic)',
			'Vehicle Trauma Kits (Advanced)',
			'Saferoom Medical Kits',
			'Medical Treatment Areas',
			'Stretchers',
			'Wheelchairs',
			'Defibrillators',
			'Ambulance',
			'Dispensary',
			'Post Exposure Prophylaxis (PEP) Kits',
			'Body Bags/Coffins',
			'Triage Cards/Markers',
			'Other',
		],
		'Personal Protection': [
			'Face Masks',
			'Eye Protection',
			'Garment Protection',
			'Head Protection',
			'Individual Emergency Bags/Grab Bags',
			'Ballistic Vest and Helmet',
			'Ballistic Blankets',
			'HAZMAT Suit',
			'Protective Gloves',
			'Protective Shoes/Covers',
			'Surface Cleaner/Sanitizer',
			'Hand Sanitizer',
			'Other',
		],
		'Road and Vehicle Safety': [
			'Tire Change Kits',
			'Road Triangles',
			'Communication Devices (phone/radio)',
			'Spare Fuel Containers',
			'Flashlights/Lanterns',
			'GPS For Navigation',
			'GPS For Vehicle Tracking',
			'Water/Food',
			'Anti-theft System',
			'Shelter Materials (blankets, tent)',
			'Armoured Vehicles',
			'Shatter Resistant Window Film',
			'4x4 Vehicles',
			'Winch',
			'Shovel, Axe',
			'Tow Rope',
			'Logos/Flags/Decals',
			'Other',
		],
		'General Safety': ['Flashlights', 'Water/Food Supplies', 'Other'],
		'Access Control': [
			'Badges and Printers',
			'X-Ray Machine',
			'Hand Held Wands',
			'Walk Through Body Scanner',
			'Itemizer (Explosives Detector)',
			'Under Vehicle Mirrors',
			'Other',
		],
	},
	Training: {
		Communications: [
			'Crisis Communications',
			'Radio Communications',
			'Warden System',
			'Accountability and Duty of Care',
			'Other',
		],
		'Crisis Management': [
			'Security Incident Mgmt. For Executives (SIME)',
			'Table-Top Exercises (Contingency Planning)',
			'General Staff Awareness and Training',
			'Critical Incident Management (CIM)',
			'Security Risk Management',
			'Other',
		],
		Driving: [
			'City and Rural Driving Techniques',
			'Security and Safety Awareness for Drivers',
			'Vehicle Maintenance and Troubleshooting',
			'Journey Mgmt. Procedures',
			'Approaching Vehicle Checkpoints',
			'Evasive Driving',
			'Other',
		],
		'Emergency Response': [
			'Earthquake Response',
			'Hibernation, Relocation and Evacuation (HRE)',
			'Lockdown Drills',
			'CERT',
			'Other',
		],
		Fire: ['Fire Drills ', 'Fire Extinguisher Use ', 'Fire Fighting', 'Other'],
		'Hostile Environment': [
			'Hostile Environment Awareness Training HEAT (in-person)',
			'Hostile Environment Awareness Training HEAT (online)',
			'Mines and UXOS*',
			'Weapons Awareness*',
			'Other',
		],
		Medical: [
			'Basic First Aid*',
			'Advanced First Aid*',
			'Paramedic Certification',
			'Psycho-Social Support',
			'Other',
		],
		'General Security and Safety': [
			'Security for Women*',
			'Personal Security Awareness*',
			'Defusing Anger*',
			'Residential Security*',
			'Surviving a Crowd*',
			'Travel Security*',
			'Surviving an Active Shooter*',
			'Hostage Survival*',
			'COVID 19: Staying Safe*',
			'Other',
		],
		'Operational Security (OPSEC)': ['OPSEC*'],
		'Policies and Guidelines Training': [
			'General Staff/Volunteer Training on Policies',
			'Management Training on Policies',
			'Other',
		],
	},
	'Policies and Procedures': {
		'Business Continuity Plan (BCP)': [
			'Business Continuity Plan (BCP)*',
			'Other',
		],
		'Medical Emergency Response (MERP)': [
			'In-Country Response ',
			'Medivac Response',
			'Insurance Coverage',
			'Other',
		],
		'Journey Management (JM)': ['Journey Management (JM)*', 'Other'],
		'Security Management Policy': [
			'General Security and Safety ',
			'Regular Testing and Maintenance of Systems',
			'Other',
		],
		'Access Control': [
			'Use and Display of Badges/ID',
			'Replacement of Badges and ID',
			'Lockdown',
			'Visitor Entry Procedures',
			'Mail and Delivery Screening',
			'People Searches',
			'Pick up and Drop off Areas',
			'CCTV Monitoring and Mgmt.',
			'Parking Control and Vehicle Tags',
			'K-9 Management',
			'Contraband Policy',
			'Key Control',
			'Other',
		],
		'Contingency Planning*': ['Contingency Planning*', 'Other'],
		'Fire Safety': [
			'Relocate/Limit Storage of Flammable Materials',
			'Maintenance of Fire Extinguishers and Equipment',
			'Fire/Evacuation Drills and Frequency',
			'Insurance Policies ',
			'Establish Fire Warden System',
			'Fire Brigade Relationship Mgmt.',
			'Other',
		],
		'Hibernation, Relocation and Evacuation (HRE)': [
			'Hibernation, Relocation and Evacuation (HRE)*',
		],
		Communications: ['Crisis Communications', 'Use of Radios', 'Other'],
		'Budgeting and Cost Sharing': ['Budgeting and Cost Sharing*'],
		'After Action Review': ['After Action Review*'],
		'Arrest and Detention': ['Arrest and Detention*'],
		'Residential Security': ['Residential Security*'],
		'Security Coordination Cell (SCC)*': ['Security Coordination Cell (SCC)*'],
		'Security Incident Reporting': ['Security Incident Reporting*'],
		'Unarmed Guards': ['Unarmed Guards*'],
		'Bomb Threat': ['Bomb Threat'],
		'Suspicious Mail': ['Suspicious Mail '],
		'AKDN Security Level System (SLS)': ['AKDN Security Level System (SLS)*'],
		'Country Security Plan': ['Country Security Plan*'],
		'Workplace Violence': ['Workplace Violence'],
		'Operational Security (OPSEC)': ['Operational Security (OPSEC)'],
		'Event Security Management': ['Event Security Management'],
		CERT: ['CERT - Establishment and Management'],
		'Critical Incident Management': [
			'Critical Incident Management Plan  (CIMP)',
		],
		'NC Baseline Standards  - Security and safety function': [
			'Definition of the security and safety function, mandate, and scope ',
		],
		'NC Baseline Standards  - Security and safety management': [
			'Security and safety management policy statement',
		],
		'NC Baseline Standards  - Team structure': [
			'Organogram (complete)',
			'Terms of reference for all functional roles',
		],
		'NC Baseline Standards  - Risk management': [
			'Overarching risk management guidelines ',
			'SRA guidelines ',
			'PVA guidelines ',
			'Risk register / tracker',
		],
		'NC Baseline Standards  - Documented plans': [
			'Guidelines for managing security and safety of JKs',
			'Guidelines for managing security and safety at Jamati properties',
			'Guidelines for managing security and safety of Jamati assets',
			'Guidelines for managing security and safety of Jamati activities / events',
		],
		'NC Baseline Standards  - Reporting': [
			'Process for monitoring ',
			'Process for reporting',
			'Process for critical incident reporting',
		],
		'NC Baseline Standards  - Incident management': [
			'Protocols for common incidents',
			'Protocols for critical incidents',
		],
		'NC Baseline Standards - Awareness / culture': [
			'Programme of engagment with institutional stakeholders',
		],
		'National Council Baseline Standards  - Training': [
			'Needs assessment',
			'Strategy and delivery plan',
		],
		'NC Baseline Standards  - Security consiousness (Jamat)': [
			'Needs assessment',
			'Strategy and delivery plan',
		],
		'NC Baseline Standards  - Neighobrhood cohesion': [
			'National strategy',
			'Operational guidelines',
		],
	},
	'Human Resources': {
		Contracting: [
			'Guards (Static/Mobile/Response)',
			'External Security Consultants',
			'Parking Area Control',
			'Access Point Control',
			'Internal Monitoring, Searches and Patrol',
			'VIP Protection',
			'Drivers',
			'Medical Support',
			'Fire Response',
			'Emergency Response',
			'OPS Room Mgmt',
			'Other',
		],
		'In-House Staffing': [
			'Guards (Static/Mobile/Response)',
			'Security Staff',
			'Parking Area Control',
			'Access Point Control',
			'Internal Monitoring, Searches and Patrol',
			'VIP Protection',
			'Drivers',
			'Medical Support',
			'Fire Response',
			'Emergency Response',
			'OPS Room Mgmt',
			'Other',
		],
		Volunteers: [
			'Appointed Security and Safety Lead ',
			'Appointed Security and Safety Team',
		],
	},
};
