import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import keycloak from './keycloak';
import {isUserAdmin} from './utils/accessControl';

const getNav = auth => {
	const nav = {
		items: [
			{
				name: 'Home',
				url: '/home',
				icon: 'icon-home',
			},
			{
				name: 'New Assessment',
				url: '/dashboard',
				icon: 'icon-speedometer',
			},
			{
				name: 'Completed Assessments',
				url: '/results',
				icon: 'icon-pie-chart',
			},
		],
	};

	try {
		if (isUserAdmin(auth)) {
			nav.items.push(
				{
					name: 'Deleted Assessments',
					url: '/deleted-results',
					icon: 'icon-trash',
				},
				{
					name: 'Users',
					url: '/users',
					icon: 'icon-user',
				},
			);
		}
	} catch (e) {
		keycloak.logout();
	}

	nav.items.push({
		name: 'Help & Feedback',
		url: '/help',
		icon: 'icon-question',
		class: 'mt-auto help-and-feedback-button',
		variant: 'success',
	});

	return nav;
};

export default getNav;
