import React from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import Loading from './components/Loading';
import DefaultLayout from './containers/DefaultLayout';
import HomeLayout from './containers/HomeLayout/HomeLayout';

// Containers
// const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const NoAuth = React.lazy(() => import('./views/Pages/NoAuth'));

const App = () => {
	const auth = useSelector(state => state.auth);

	if (!auth.user) return <Loading>Getting user information...</Loading>;

	return (
		<BrowserRouter>
			<React.Suspense fallback={<Loading>Loading page...</Loading>}>
				<Switch>
					<Route
						exact
						path="/register"
						name="Register Page"
						render={props => <Register {...props} />}
					/>
					<Route
						exact
						path="/404"
						name="Page 404"
						render={props => <Page404 {...props} />}
					/>
					<Route
						exact
						path="/500"
						name="Page 500"
						render={props => <Page500 {...props} />}
					/>
					<Route
						exact
						path="/no-auth"
						name="No Auth"
						render={props => <NoAuth {...props} />}
					/>
					{/*This redirect is necessary for homepage*/}
					<Redirect exact from="/" to="/home" />
					<Route exact path={'/home'} name="HomePage" component={HomeLayout} />
					<Route path={'/'} name="Home" component={DefaultLayout} />
					<Redirect to="/404" name="Page 404" />
				</Switch>
			</React.Suspense>
			<ToastContainer />
		</BrowserRouter>
	);
};

export default App;
