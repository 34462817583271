import {
	ASSESSMENT_LOAD,
	ASSESSMENT_RESET,
	ASSESSMENT_UPDATE,
} from '../actionTypes';

const initialState = {
	assessmentInProgress: false,
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case ASSESSMENT_LOAD:
			return {
				...state,
				assessmentInProgress: true,
			};
		case ASSESSMENT_RESET:
			return {
				...state,
				assessmentInProgress: false,
			};
		case ASSESSMENT_UPDATE:
			return {
				...state,
				assessmentInProgress: true,
			};
		default:
			return state;
	}
};

export default appReducer;
