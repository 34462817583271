import {AppHeaderDropdown} from '@coreui/react';
import Headroom from 'headroom.js';
import md5 from 'js-md5';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	NavbarBrand,
} from 'reactstrap';
import keycloak from '../../keycloak';

const Header = () => {
	const [emailHash, setEmailHash] = useState('');

	useEffect(() => {
		const navbarElement = document.querySelector('#navbar');
		const headroom = new Headroom(navbarElement);
		headroom.init();
	}, []);

	const auth = useSelector(state => state.auth);

	const {user} = auth;

	useEffect(() => {
		if (user) {
			let emailHash = md5(user.email);
			setEmailHash(emailHash);
		}
	}, [user]);

	if (!user) return null;

	return (
		<Navbar className={'headroom'} id={'navbar'}>
			<NavbarBrand href="/">
				<img src={'/assets/img/akdn-logo-white.png'} alt={'logo'} />
				SECURITY RISK ASSESSMENT TOOL
			</NavbarBrand>
			<Nav className="mr-auto" />

			<AppHeaderDropdown direction="down">
				<DropdownToggle nav>
					<div>
						<img
							src={`/assets/img/default-users.png`}
							className="img-avatar"
							alt={user.email}
						/>
					</div>

					<div className={'user-name'}>{`${user.name} ${user.surname}`}</div>
				</DropdownToggle>
				<DropdownMenu style={{right: 'auto'}}>
					<DropdownItem tag="div" className="text-center">
						<strong>Account</strong>
					</DropdownItem>

					<DropdownItem onClick={() => keycloak.accountManagement()}>
						<i className="fa fa-gear" /> Manage Account
					</DropdownItem>
					<DropdownItem tag={Link} to={'/help'}>
						<i className="fa fa-question" /> Help & Feedback
					</DropdownItem>
					<DropdownItem onClick={() => keycloak.logout()}>
						<i className="fa fa-lock" /> Logout
					</DropdownItem>
				</DropdownMenu>
			</AppHeaderDropdown>
		</Navbar>
	);
};

export default Header;
