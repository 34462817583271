import {toast} from 'react-toastify';
import {v4 as uuid} from 'uuid';
import {
	impactInfoData,
	riskValues,
	vulnerabilityInfoData,
} from '../../views/Dashboard/constants';
import {deepClone, objectFromEntries} from '../../views/Dashboard/utils';
import {
	ASSESSMENT_ADD_THREAT,
	ASSESSMENT_LOAD,
	ASSESSMENT_REMOVE_THREAT,
	ASSESSMENT_REPLACE_THREAT,
	ASSESSMENT_RESET,
	ASSESSMENT_SET_COASSESSORS,
	ASSESSMENT_THREAT_ADD_TASK,
	ASSESSMENT_THREAT_REMOVE_TASK,
	ASSESSMENT_THREAT_UPDATE_TASK,
	ASSESSMENT_UPDATE,
	ASSESSMENT_UPDATE_THREAT,
} from '../actionTypes';

export const createNewTask = () => {
	return {
		key: uuid(),
		strategy: undefined,
		category: undefined,
		feature: undefined,
		contMeasDetails: '',
		responsiblePerson: undefined,
		deadline: undefined,
		dateCompleted: undefined,
	};
};

export const createNewThreat = () => {
	return {
		key: uuid(),
		type: undefined,
		category: undefined,
		description: '',
		score: 5,
		title: '',
		points: {
			capability: 1,
			history: 1,
			intention: 1,
		},
		likelihood: 1,
		impactPoints: objectFromEntries(impactInfoData.map(i => [i.title, 1])),
		risk: 1,
		riskManagement: {
			currentControl: '',
			impactedEntity: [],
		},
		tasks: [createNewTask()],
	};
};

export const createNewAssessment = () => {
	return {
		scope: {
			assessor: '',
			agency: undefined,
			type: undefined,
			description: '',
			country: undefined,
			dateCompleted: undefined,
			year: new Date().getFullYear(),
			address: '',
		},
		threats: [createNewThreat()],
		vulnerability: objectFromEntries(
			vulnerabilityInfoData.map(i => [i.title, 1]),
		),
		notes: {},
		user: undefined,
	};
};

export const updateAssessment = (field, subField, value) => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_UPDATE,
			payload: {
				field,
				subField,
				value,
			},
		});
	};
};

export const resetAssessment = () => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_RESET,
		});
	};
};

export const loadAssessment = assessment => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_LOAD,
			payload: {
				...assessment,
				threats: assessment.threats.map(threat => ({
					...threat,
					key: threat.key ? threat.key : uuid(),
					tasks: threat.tasks.map(task => ({
						...task,
						key: task.key ? task.key : uuid(),
					})),
				})),
			},
		});
	};
};

export const addThreatToAssessment = () => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_ADD_THREAT,
			payload: createNewThreat(),
		});
	};
};

export const removeThreatFromAssessment = key => {
	return (dispatch, getState) => {
		const {
			assessment: {threats},
		} = getState();

		if (threats.length <= 1) {
			toast.error('Cannot delete last threat!', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		} else {
			dispatch({
				type: ASSESSMENT_REMOVE_THREAT,
				payload: {key},
			});
		}
	};
};

export const updateThreatOfAssessment = (key, field, value) => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_UPDATE_THREAT,
			payload: {key, field, value},
		});
	};
};

export const replaceThreatOfAssessment = (key, newThreat) => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_REPLACE_THREAT,
			payload: {key, newThreat},
		});
	};
};

const _calculateRisks = threat => {
	const impact = Math.max(...Object.values(threat.impactPoints));

	const likelihood = threat.likelihood;

	threat.risk = riskValues[likelihood - 1][impact - 1];
};

export const updateLikelihoodOfThreat = (threatIndex, value) => {
	return (dispatch, getState) => {
		const {
			assessment: {threats},
		} = getState();

		const threat = threats[threatIndex];

		const newThreat = deepClone(threat);
		newThreat.likelihood = value;

		_calculateRisks(newThreat);

		dispatch({
			type: ASSESSMENT_REPLACE_THREAT,
			payload: {key: newThreat.key, newThreat},
		});
	};
};

export const updateImpactOfThreat = (threatIndex, field, value) => {
	return (dispatch, getState) => {
		const {
			assessment: {threats},
		} = getState();

		const threat = threats[threatIndex];

		const newThreat = deepClone(threat);
		newThreat.impactPoints[field] = value;

		_calculateRisks(newThreat);

		dispatch({
			type: ASSESSMENT_REPLACE_THREAT,
			payload: {key: newThreat.key, newThreat},
		});
	};
};

export const updateRiskManagementOfThreat = (threatIndex, field, value) => {
	return (dispatch, getState) => {
		const {
			assessment: {threats},
		} = getState();

		const threat = threats[threatIndex];

		const newThreat = deepClone(threat);
		newThreat.riskManagement[field] = value;

		_calculateRisks(newThreat);

		dispatch({
			type: ASSESSMENT_REPLACE_THREAT,
			payload: {key: newThreat.key, newThreat},
		});
	};
};

export const addTaskToThreat = threatKey => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_THREAT_ADD_TASK,
			payload: {
				threatKey,
				newTask: createNewTask(),
			},
		});
	};
};

export const updateTaskOfThreat = (threatKey, taskKey, field, value) => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_THREAT_UPDATE_TASK,
			payload: {threatKey, taskKey, field, value},
		});
	};
};

export const removeTaskFromThreat = (threatKey, taskKey) => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_THREAT_REMOVE_TASK,
			payload: {threatKey, taskKey},
		});
	};
};

export const setCoassessors = value => {
	return dispatch => {
		dispatch({
			type: ASSESSMENT_SET_COASSESSORS,
			payload: value,
		});
	};
};
