export function deepClone(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}

	if (obj instanceof Date) {
		return new Date(obj.getTime());
	}

	if (obj instanceof Array) {
		return obj.reduce((arr, item, i) => {
			arr[i] = deepClone(item);
			return arr;
		}, []);
	}

	if (obj instanceof Object) {
		return Object.keys(obj).reduce((newObj, key) => {
			newObj[key] = deepClone(obj[key]);
			return newObj;
		}, {});
	}
}

export function objectFromEntries(arr) {
	return arr.reduce(
		(acc, i) => ({
			...acc,
			[i[0]]: i[1],
		}),
		{},
	);
}
